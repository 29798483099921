const soundPopUp = (soundAllowed) => {   
  
  $(window).scroll(function() {

        // ****
        // **** PHONEBOOTH 
        // ****
        var soundPopUpElement = $('.ativar-som');

        if ($(window).width() >= 768 ) {
          if ( ($(window).scrollTop() > $('.phonebooth-section').offset()["top"]) && ( $(window).scrollTop() < ($('.hd-mt-mobile').offset()["top"] + $('.hd-mt-mobile').height() ) ) ) {
            soundPopUpElement.css('opacity', '1');
          } else {
            soundPopUpElement.css('opacity', '0');
          };
        } else {
          if ( $(window).scrollTop() > ($('.phonebooth-section').offset()["top"] + $('.hd-mt-mobile').height()) && ( $(window).scrollTop() < ($('.hd-mt-mobile').offset()["top"] + $('.hd-mt-mobile').height() ) ) )  {
            soundPopUpElement.css('opacity', '1');
          } else {
            soundPopUpElement.css('opacity', '0');
          };
        };
    });
};

export { soundPopUp };