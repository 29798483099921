
const formScript = () => {

    $('#hd-form-button').on('click', function(e) {
        e.preventDefault();
        console.log($('#hd-form').serialize());
        $.ajax({
            type: "POST",
            url: 'contact',
            data: $('#hd-form').serialize() + '&submit=submit',
            success: function(data){
                if ((data["error"] === null)) {
                    $('#error-container').empty();
                    $('#hd-message-success-popup').html(data["success"]);
                    $('.alert-success').addClass('show');
                    $('#hd-message-success').removeClass('d-none');
                    $('#hd-form').addClass('d-none');
                } else {
                    // $('#hd-message-error-popup').html(data["error"]["email"]);
                    if ( data["error"].hasOwnProperty('name') ) {
                        $('#error-container').empty();
                        $('#error-container').html(
                        `<div class="alert alert-danger alert-dismissible fade show" role="alert">
                            <p class="m-0 mr-4" id="hd-message-success-popup">${ data["error"]["name"] }</p>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>`);

                        // $('#hd-message-error-popup').html(data["error"]["name"]);
                        // $('.alert-danger').addClass('show');
                    } else if ( data["error"].hasOwnProperty('email') ) {
                        $('#error-container').empty();
                        $('#error-container').html(
                        `<div class="alert alert-danger alert-dismissible fade show" role="alert">
                            <p class="m-0 mr-4" id="hd-message-success-popup">${ data["error"]["email"] }</p>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>`);
                        // $('#hd-message-error-popup').html(data["error"]["email"]);
                        // $('.alert-danger').addClass('show');
                    } else if ( data["error"].hasOwnProperty('tel') ) {
                        $('#error-container').empty();
                        $('#error-container').html(
                        `<div class="alert alert-danger alert-dismissible fade show" role="alert">
                            <p class="m-0 mr-4" id="hd-message-success-popup">${ data["error"]["tel"] }</p>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>`);
                        // $('#hd-message-error-popup').html(data["error"]["tel"]);
                        // $('.alert-danger').addClass('show');
                    } else {
                        $('#error-container').empty();
                        $('#error-container').html(
                        `<div class="alert alert-danger alert-dismissible fade show" role="alert">
                            <p class="m-0 mr-4" id="hd-message-success-popup">${ data["error"]["error"] }</p>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>`);
                    };
                    // $('#hd-message-error-popup').html(data["error"]);
                    // $('.alert-danger').addClass('show');
                };
            }
        });
    });
};

export { formScript };