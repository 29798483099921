
import { carouseis } from './honestDetails/carouseis.js';
import { formScript } from './honestDetails/form.js';
import { doorSequence } from './honestDetails/doorSequence.js';
import { soundPopUp } from './honestDetails/soundPopUp.js';
import { textAnimation } from './honestDetails/textAnimation.js';
import { hdSroll } from './honestDetails/scrollJs.js';
import { configButton } from './honestDetails/configuradorButton.js';

var soundAllowed = false;

hdSroll();
carouseis();
doorSequence(soundAllowed);
soundPopUp(soundAllowed);
formScript();
textAnimation();
configButton();