// This section refers to Pormenores Text

// let outer = document.querySelector("#outer");
// let content = outer.querySelector('#content');

// repeatContent(content, outer.offsetWidth);

// let el = outer.querySelector('#loop');
// el.innerHTML = el.innerHTML + el.innerHTML;

// function repeatContent(el, till) {
//     let html = el.innerHTML;
//     let counter = 0; // prevents infinite loop
    
//     while (el.offsetWidth < till && counter < 100) {
//         el.innerHTML += html;
//         counter += 1;
//     }
// };
// ---------

// This section refers to Module Text
// let outerTwo = document.querySelector("#outerTwo");
// let contentTwo = outerTwo.querySelector('#contentTwo');

// repeatContentTwo(contentTwo, outerTwo.offsetWidth);

// let moduleText = outerTwo.querySelector('#loopTwo');
// moduleText.innerHTML = moduleText.innerHTML + moduleText.innerHTML;

// function repeatContentTwo(moduleText, till) {
//     let html = moduleText.innerHTML;
//     let counter = 0; // prevents infinite loop
    
//     while (moduleText.offsetWidth < till && counter < 100) {
//         moduleText.innerHTML += html;
//         counter += 1;
//     }
// };
//----------

// This section refers to Galeria Text

// let outerThree = document.querySelector("#outerThree");
// let contentThree = outerThree.querySelector('#contentThree');

// repeatContentThree(contentThree, outerThree.offsetWidth);

// let galleryText = outerThree.querySelector('#loopThree');
// galleryText.innerHTML = galleryText.innerHTML + galleryText.innerHTML;

// function repeatContentThree(galleryText, till) {
//     let html = galleryText.innerHTML;
//     let counter = 0; // prevents infinite loop
    
//     while (galleryText.offsetWidth < till && counter < 100) {
//         galleryText.innerHTML += html;
//         counter += 1;
//     }
// };

// var distanceScroll = 0;

// $(window).scroll(function(){


//     var dsad = $(window).scrollTop();
//     if ( dsad > 100 ) { 
//         console.log("hi");
//     }
// });
// var target = $('#loop');
// // loop-inital
// // loop-reverse

// // loop-initial-fast
// // loop-reverse-fast


  
// var $tickerWrapper = $(".tickerwrapper");
// var $list = $tickerWrapper.find("ul.list");
// var $clonedList = $list.clone();
// var listWidth = 10;
// 		$list.find("li").each(function (i) {
// 			listWidth += $(this, i).outerWidth(true);
// 		});

// var endPos = $tickerWrapper.width() - listWidth;

// $list.add($clonedList).css({
// 	"width" : listWidth + "px"
// });

// $clonedList.addClass("cloned").appendTo($tickerWrapper);

// // Creathing Add new

// //-- End Creathing add
// //TimelineMax
// // var infinite = gsap.timeline({force3D:true, repeat: -1, paused: false});


// var infinite = gsap.timeline({
//     repeat: -1, 
//     paused: false,

// });

// var time = 100;

// infinite.fromTo($list, time, {x:0}, {x: -listWidth, ease: Linear.easeNone}, 0);
// infinite.fromTo($clonedList, time, {x:listWidth}, {x:0, ease: Linear.easeNone}, 0);
// infinite.set($list, {x: listWidth});
// infinite.to($clonedList, time, {x: -listWidth, ease: Linear.easeNone}, time);
// infinite.to($list, time, {x: 0, ease: Linear.easeNone}, time);
 



// $(window).bind('mousewheel', function(event) {
//     if (event.originalEvent.wheelDelta >= 0) {
//         console.log('Scroll up');

//         if (!infinite.reversed() ) {
//             infinite.reverse();
//         }


//     }
//     else {
//         console.log('Scroll down');
//         if (infinite.reversed() ) {
//             infinite.play();
//         }
//     }
// });



//Pause/Play
				
// $tickerWrapper.on("mouseenter", function(){
// 	infinite.pause();
// }).on("mouseleave", function(){
// 	infinite.play();
// });
// gsap.registerPlugin(ScrollTrigger);
// gsap.utils.toArray('section-test').forEach((section, index) => {
//     const w = section.querySelector('.wrapper');
//     const [x, xEnd] = (index % 2) ? ['100%', (w.scrollWidth - section.offsetWidth) * -1] : [w.scrollWidth * -1, 0];
//     gsap.fromTo(w, {  x  }, {
//       x: xEnd,
//       scrollTrigger: { 
//         trigger: section, 
//         scrub: 0.5 
//       }
//     });
//   });

const textAnimation = () => {

    
        var containers = $('.containerr');

        if (containers.length) {
            containers.each(function() {
                var container = $(this);

                // Support small text - copy to fill screen width
                if (container.find('.scrolling-text').outerWidth() < $(window).width()) {
                    var windowToScrolltextRatio = Math.round($(window).width() / container.find('.scrolling-text').outerWidth()),
                        scrollTextContent = container.find('.scrolling-text .scrolling-text-content').text(),
                        newScrollText = '';
                    for (var i = 0; i < windowToScrolltextRatio; i++) {
                        newScrollText += ' ' + scrollTextContent;
                    }
                    container.find('.scrolling-text .scrolling-text-content').text(newScrollText);
                }

                // Init variables and config
                var scrollingText = container.find('.scrolling-text'),
                    scrollingTextWidth = scrollingText.outerWidth(),
                    scrollingTextHeight = scrollingText.outerHeight(true),
                    startLetterIndent = parseInt(scrollingText.find('.scrolling-text-content').css('font-size'), 10) / 4.8,
                    startLetterIndent = Math.round(startLetterIndent),
                    scrollAmountBoundary = Math.abs($(window).width() - scrollingTextWidth),
                    transformAmount = 0,
                    leftBound = 0,
                    rightBound = scrollAmountBoundary,
                    transformDirection = container.hasClass('left-to-right') ? -1 : 1,
                    transformSpeed = 10;

                // Read transform speed
                if (container.attr('speed')) {
                    // transformSpeed = container.attr('speed');
                }
            
                // Make scrolling text copy for scrolling infinity
                container.append(scrollingText.clone().addClass('scrolling-text-copy'));
                container.find('.scrolling-text').css({'position': 'absolute', 'left': 0});
                container.css('height', scrollingTextHeight);
            
                var getActiveScrollingText = function(direction) {
                    var firstScrollingText = container.find('.scrolling-text:nth-child(1)');
                    var secondScrollingText = container.find('.scrolling-text:nth-child(2)');
            
                    var firstScrollingTextLeft = parseInt(container.find('.scrolling-text:nth-child(1)').css("left"), 10);
                    var secondScrollingTextLeft = parseInt(container.find('.scrolling-text:nth-child(2)').css("left"), 10);
            
                    if (direction === 'left') {
                        return firstScrollingTextLeft < secondScrollingTextLeft ? secondScrollingText : firstScrollingText;
                    } else if (direction === 'right') {
                        return firstScrollingTextLeft > secondScrollingTextLeft ? secondScrollingText : firstScrollingText;
                    }
                }
                
                var previousScroll = 0;

                $(window).on('scroll', function(e) {
                    // var delta = e.originalEvent.deltaY;
                    var delta = $(this).scrollTop();

                    if (delta > previousScroll) {
                        // going down
                        //transformSpeed
                        transformAmount += transformSpeed * transformDirection;
                        container.find('.scrolling-text .scrolling-text-content').css('transform', 'skewX(5deg)');

                    }
                    else {
                        //transformSpeed
                        transformAmount -= transformSpeed * transformDirection;
                        container.find('.scrolling-text .scrolling-text-content').css('transform', 'skewX(-5deg)');
                    }
                    previousScroll = delta;
                    setTimeout(function(){
                        container.find('.scrolling-text').css('transform', 'translate3d('+ transformAmount * -1 +'px, 0, 0)');
                    }, 10);
                    setTimeout(function() {
                        container.find('.scrolling-text .scrolling-text-content').css('transform', 'skewX(0)');
                    }, 500)
            
                    // Boundaries
                    if (transformAmount < leftBound) {
                        var activeText = getActiveScrollingText('left');
                        activeText.css({'left': Math.round(leftBound - scrollingTextWidth - startLetterIndent) + 'px'});
                        leftBound = parseInt(activeText.css("left"), 10);
                        rightBound = leftBound + scrollingTextWidth + scrollAmountBoundary + startLetterIndent;
            
                    } else if (transformAmount > rightBound) {
                        var activeText = getActiveScrollingText('right');
                        activeText.css({'left': Math.round(rightBound + scrollingTextWidth - scrollAmountBoundary + startLetterIndent) + 'px'});
                        rightBound += scrollingTextWidth + startLetterIndent;
                        leftBound = rightBound - scrollingTextWidth - scrollAmountBoundary - startLetterIndent;
                    }
                });
            })
        }

};

export { textAnimation };