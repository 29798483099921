const carouseis = () => {

    // ****
    // ********CAROUSEIS MODULOS
    // ******
    var owl = $('#moduleCarouselWau').owlCarousel({
        items: 1,
        loop: true,
        autoplayHoverPause: true,
        dots: false,
        nav: false,
        margin: -2,
        autoplay: true,
        autoplayTimeout: 7000,
        animateIn: true,
        animateOut: true,
        responsiveClass:true,
        autowidth: true,
        responsive: {
            992 : {
                items: 2,
            }
        }
    });
    var owlSecond = $('#moduleCarouselCube').owlCarousel({
        items: 1,
        loop: true,
        autoplayHoverPause: true,
        dots: false,
        nav: false,
        margin: -2,
        autoplay: true,
        autoplayTimeout: 7000,
        animateIn: true,
        animateOut: true,
        responsive: {
            992 : {
                items: 2,
            }
        }
    });

    var owlMobile = $('.moduleWauMobile').owlCarousel({
        items: 1,
        loop: true,
        autoplayHoverPause: true,
        dots: false,
        nav: false,
        margin: -2,
        autoplay: true,
        autoplayTimeout: 7000,
        animateIn: true,
        animateOut: true,
    });
       
    // var owl = $('#moduleCarouselWau');
    // var owlSecond= $('#moduleCarouselCube');
    // var owlMobile = $('.moduleWauMobile');

    // owlSecond.owlCarousel();
    // owl.owlCarousel();
    // Go to the next item
    $('.moduleNextBtn').click(function() {
        
        // WAU
        owl.trigger('next.owl.carousel', [300]);
        owl.trigger('stop.owl.autoplay');
        owl.trigger('play.owl.autoplay',[4000]);

        //Cube
        owlSecond.trigger('next.owl.carousel', [300]);
        owlSecond.trigger('stop.owl.autoplay');
        owlSecond.trigger('play.owl.autoplay',[4000]);

        owlMobile.trigger('next.owl.carousel', [300]);
        owlMobile.trigger('stop.owl.autoplay');
        owlMobile.trigger('play.owl.autoplay',[4000]);
    });
    // Go to the previous item
    $('.modulePrevBtn').click(function() {
        // WAU
        owl.trigger('prev.owl.carousel', [300]);
        owl.trigger('stop.owl.autoplay');
        owl.trigger('play.owl.autoplay',[4000]);
        //Cube
        owlSecond.trigger('prev.owl.carousel', [300]);
        owlSecond.trigger('stop.owl.autoplay');
        owlSecond.trigger('play.owl.autoplay',[4000]);

        owlMobile.trigger('prev.owl.carousel', [300]);
        owlMobile.trigger('stop.owl.autoplay');
        owlMobile.trigger('play.owl.autoplay',[4000]);
    });

    var wauCarousel = $('.moduleWau');
    var cubeCarousel = $('.moduleCube');

    // Module Selectors - Opacity change and position reset
        // Wau
    $('.moduleSelectorWau').on('click', function(){
        cubeCarousel.css({ "opacity": "0" , "visibility": "hidden"});
        wauCarousel.css({ "opacity": "1" , "visibility": "visible"});   

        owl.trigger('to.owl.carousel',[0, 500]);


        if (!($(this).hasClass('module-slider-active'))) {
            $(this).addClass('module-slider-active') 
            $('.moduleSelectorCube').removeClass('module-slider-active')       
        }
    });
        // Cube
    $('.moduleSelectorCube').on('click', function(){

        cubeCarousel.css({ "opacity": "1" , "visibility": "visible"});
        wauCarousel.css({ "opacity": "0" , "visibility": "hidden"});          

        owlSecond.trigger('to.owl.carousel',[0, 500]);

        if (!($(this).hasClass('module-slider-active'))) {
            $(this).addClass('module-slider-active') 
            $('.moduleSelectorWau').removeClass('module-slider-active')       
        }
    });
    
    $('.module-height').css('height', (cubeCarousel.outerHeight() + "px" ));

    var carouselWidth = $('.module-carousel-container').innerWidth() - parseInt($('.module-carousel-container').css('padding-right'));

    $('.moduleCube').css('width', (carouselWidth + "px" ) );
    wauCarousel.css('width', carouselWidth + "px" );

    // ****
    // ******** CAROUSEL PORMENORES
    // ******
    $(document).ready(function() {
        var detailsCarousel = $('#detailsCarousel');
        var activeSlide = "";
        var totalSlides= "";
        detailsCarousel.on('changed.owl.carousel', function (e) {
            // console.log("current: ",e.relatedTarget.current())
            // console.log("current: ",e.item.index) //same
            // console.log("total: ",e.item.count)   //total
            activeSlide = "0" + (e.item.index - 2);
            $('.details-carousel-slide-indicator-active').text(activeSlide);
            // console.log("total: ",e.item.count)   //total
            totalSlides = "0" + e.item.count;
            $('.details-carousel-slide-indicator-total').text(totalSlides);
            
        });
        detailsCarousel.owlCarousel({
            items: 1,
            loop: true,
            autoplayHoverPause: true,
            dots: false,
            nav: false,
            autoWidth:true,
            autoplay: true,
            animateIn: true,
            animateOut: true,
            margin: 0,
            responsiveClass:true,
            autoWidth: true,
            responsive: {
                768 : {
                    items: 3,
                    margin: 30,
                }
            }
        });
        // Go to the next item
        $('.detailsNextBtn').click(function() {
            detailsCarousel.trigger('next.owl.carousel', [300]);
            detailsCarousel.trigger('stop.owl.autoplay');
            detailsCarousel.trigger('play.owl.autoplay',[4000]);
        });
        // Go to the previous item
        $('.detailsPrevBtn').click(function() {
            detailsCarousel.trigger('prev.owl.carousel', [300]);
        });
    
        $('.slider-hotspot').hover(function() {
    
            if( $(window).width() < 576) {
                $('.details-slide-info').css("opacity", "1");
            } else {
                $('.details-slide-info:first-child').css("opacity", "1");
            }
          }, function() {
            // on mouseout, reset the background colour
            if( $(window).width() < 576) {
                $('.details-slide-info').css("opacity", "0");
            } else {
                $('.details-slide-info:first-child').css("opacity", "0");
            }
        });
    });
    
    

    // ****
    // ******** CAROUSEL GALERIA
    // ******

    $('#galleryCarousel').owlCarousel({
        items: 1,
        loop: true,
        autoplayHoverPause: true,
        dots: false,
        nav: false,
        margin: 0,
        autoplay: true,
        animateIn: true,
        animateOut: true,
        responsiveClass:true,
        responsive: {
            600 : {
                items: 2,
                margin: 20,
            },
            768 : {
                items: 3,
                margin: 20,
            }
        },
    });

    var galleryCarousel = $('#galleryCarousel');
    galleryCarousel.owlCarousel();
    // Go to the next item
    $('.galleryNextBtn').click(function() {
        galleryCarousel.trigger('next.owl.carousel', [300]);
    })
    // Go to the previous item
    $('.galleryPrevBtn').click(function() {
        // With optional speed parameter
        // Parameters has to be in square bracket '[]'
        galleryCarousel.trigger('prev.owl.carousel', [300]);
    })

    // Add Jquery Calculation of Height of carousel, height of controls, and place it in the middle

    // **** FIM

};

export { carouseis };