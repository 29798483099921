const doorSequence = (soundAllowed) => {
gsap.registerPlugin(ScrollTrigger);

var myAudio = document.getElementById("myAudio"); 

var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
if (!isChrome){
    $('#iframeAudio').remove()
}
else {
    $('#myAudio').remove() // just to make sure that it will not have 2x audio in the background 
}
// var soundAllowed = false;

const canvas = document.getElementById("hero-lightpass");
const context = canvas.getContext("2d");

canvas.width = 1920;
canvas.height = 1080;

const frameCount = 120;
const currentFrame = index => (
  `assets/media/Phonebooth/frames/WAU_1M_Animation_Door_000.${index}.jpg`
);

const images = []
const airpods = {
  frame: 0
};

for (let i = 0; i < frameCount; i++) {
  const img = new Image();
  img.src = currentFrame(i);
  images.push(img);
}

gsap.to(airpods, {
  frame: frameCount - 1,
  snap: "frame",
  scrollTrigger: {
  // start: "top -400px",
  trigger: ".sequence-fixed",
  // trigger: doorTrigger(),
  // start: "top -100px",
  start: doorStart(),
  pin: true,
  scrub: 0.5
  },
  onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate
});

images[0].onload = render;

function render() {
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.drawImage(images[airpods.frame], 0, 0); 
  sound(airpods.frame, myAudio);
}

// function doorStart() {
//   windowWidth = $(window).width();
//   listHeight = $('.hd-phonebooth-list-mt').height();
//   if (windowWidth > 992) {
//     return "top +=300px"
//   } else if ( (windowWidth < 992) && (windowWidth > 600) ) {
//     return "bottom +=700px"
//   } else {
//     return "bottom +=500px"
//   };
// };

function doorStart() {
  var windowWidth = $(window).width();
  var listHeight = $('.hd-phonebooth-list-mt').height();
  if (windowWidth > 992) {
    return "top -100px"
  } else if ( (windowWidth < 992) && (windowWidth > 600) ) {
    return "bottom bottom"
  } else {
    return "bottom +=520px"
  };
};

/* ---- POP UP VISIVEL NO SCROLL ---- */
var soundPopUp = $('.ativar-som');

// $(window).scroll(function() {

//   // queries width


//   // entre inicio de phoneboothsection e final
//   if ($(window).width() >= 768 ) {
//     if ( ($(window).scrollTop() > $('.phonebooth-section').offset()["top"]) && ( $(window).scrollTop() < ($('.hd-mt-mobile').offset()["top"] + $('.hd-mt-mobile').height() ) ) ) {
//       soundPopUp.css('opacity', '1');
//     } else {
//       soundPopUp.css('opacity', '0');
//     };
//   } else {
//     if ( $(window).scrollTop() > ($('.phonebooth-section').offset()["top"] + $('.hd-mt-mobile').height()) )  {
//       soundPopUp.css('opacity', '1');
//     } else {
//       soundPopUp.css('opacity', '0');
//     };
//   }

//   //scroll maior que 100 e sound allowed = false
//   if( ($(window).scrollTop() > 100 ) && ( !soundAllowed) ) {
//     soundPopUp.css('display', 'block');
//   } else {
//     soundPopUp.css('display','none');
//   }

// });

$('.ativar-som-botão').click(function(){

  if (myAudio.muted) {
    myAudio.muted = false;
    soundAllowed = true;
    soundPopUp.css('opacity','0');
    setTimeout(function(){ soundPopUp.css('display','none'); }, 1000);

  } else {
    myAudio.muted = true;
    soundAllowed = false;
  }
  myAudio.play();
});

$(window).scroll(function() {
  //scroll maior que 100 e sound allowed = false
  if( ($(window).scrollTop() > 100 ) && ( !soundAllowed) ) {
    $('.ativar-som').css('display', 'block');
  } else {
    $('.ativar-som').css('display','none');
  };
});

// $(document).ready(function(){
//   ativarSomPosition()
// });

// function ativarSomPosition() {
//   canvas
//   var canvasWidth = canvas.offsetWidth;
//   var canvasHeight = canvas.offsetHeight;
//   var canvasOffsetLeft = $(canvas).offset()["left"];
//   var canvasOffsetTop = $(canvas).offset()["top"];

//   var ativarSom = $('.ativar-som');
//   var ativarSomWidth = ativarSom.find('.ativar-som-botão').width();
//   var ativarSomHeigth = ativarSom.find('.ativar-som-botão').height();
  
//   // Calculo posição left
//   var ativarSomLeft = ((canvasWidth/2) - (ativarSomWidth/2)) + canvasOffsetLeft; 
//   var ativarSomTop = ((canvasHeight/2) - (ativarSomHeigth/2)) + canvasOffsetTop; 
//   $(ativarSom).css({
//     'left': ativarSomLeft + "px"
//   });
// };


  // canvas
  // var canvasWidth = canvas.offsetWidth;
  // var canvasHeight = canvas.offsetHeight;
  // var canvasOffsetLeft = $(canvas).offset()["left"];
  // var canvasOffsetTop = $(canvas).offset()["top"];

  // var ativarSom = $('.ativar-som');
  // var ativarSomWidth = ativarSom.find('.ativar-som-botão').width();
  // var ativarSomHeigth = ativarSom.find('.ativar-som-botão').height();
  
  // // Calculo posição left
  // var ativarSomLeft = ((canvasWidth/2) - (ativarSomWidth/2)) + canvasOffsetLeft; 
  // var ativarSomTop = ((canvasHeight/2) - (ativarSomHeigth/2)) + canvasOffsetTop; 
  // $(ativarSom).css({
  //   'left': ativarSomLeft + "px",
  //   'top': ativarSomTop + "px"
  // });



// function sound(index, audio) {
//   if( (index > 7) && (index <= 15 )  ) { 
//     if (soundAllowed) {
//       audio.muted = false;
//     }
//     audio.volume = 0.05;
//   } else if ( (index > 15) && (index <= 25) ) {
//     audio.volume = 0.1;
//   } else if ( (index > 25) && (index <= 32) ) {
//     audio.volume = 0.15;
//   } else if ( (index > 32) && (index <= 40) ) {
//     audio.volume = 0.2;
//   } else if ( (index > 40) && (index <= 90) ) {
//     audio.volume = 0.3;
//   } else if ( (index > 90) && (index <= 100) ) {
//     audio.volume = 0.2;
//   } else if ( (index > 100) && (index <= 110) ) {
//     audio.volume = 0.1;
//   } else if ( (index > 110) && (index <= 118) ) {
//     audio.volume = 0.05;
//   } else if ( (index > 118) ) {
//     if (soundAllowed) {
//       audio.muted = false;
//     }
//     audio.volume = 0;
//   } else {
//     audio.volume = 0;
//     if (!soundAllowed) {
//       audio.muted = true;
//     }
//   };
// };
function sound(index, audio) {
  if( (index > 7) && (index <= 15 )  ) { 
    if (soundAllowed) {
      audio.muted = false;
    }
    audio.volume = 0.05;
  } else if ( (index > 15) && (index <= 20) ) {
    audio.volume = 0.1;
  } else if ( (index > 20) && (index <= 25) ){
    audio.volume = 0.15;
  } else if ( (index > 25) && (index <= 32) ) {
    audio.volume = 0.2;
  } else if ( (index > 32) && (index <= 40) ) {
    audio.volume = 0.3;
  } else if ( (index > 40) && (index <= 90) ) {
    audio.volume = 0.4;
  } else if ( (index > 90) && (index <= 100) ) {
    audio.volume = 0.3;
  } else if ( (index > 100) && (index <= 105) ) {
    audio.volume = 0.2;
  } else if( (index > 105) && (index <= 110) ) {
    audio.volume = 0.15;
  } else if ( (index > 110) && (index <= 114) ) {
    audio.volume = 0.1;
  } else if ( (index > 114) && (index <= 118) ) {
    audio.volume = 0.05;
  } else if ( (index > 118) ) {
    if (soundAllowed) {
      audio.muted = false;
    }
    audio.volume = 0;
  } else {
    audio.volume = 0;
    if (!soundAllowed) {
      audio.muted = true;
    };
  };
};

gsap.to(".hd-phonebooth-list-mt", {
  yPercent: 5,
  ease: "none",
  scrollTrigger: {
    trigger: ".phonebooth-section",
    start: "top bottom", // the default values
    end: "bottom top",
    scrub: true
  }, 
});


/*----- Details parallaxs ---*/
gsap.to(".parallax-img", {
  yPercent: 20,
  ease: "none",
  scrollTrigger: {
    trigger: ".pormenores-section",
    start: "top bottom", // the default values
    end: "bottom top",
    scrub: true
  }, 
});

gsap.to(".details-text-parallax", {
  yPercent: -100,
  ease: "none",
  scrollTrigger: {
    trigger: ".pormenores-section",
    start: "top top", // the default values
    end: "bottom top",
    scrub: true
  }, 
});

gsap.to(".parallax-img-second", {
  yPercent:-5,
  ease: "none",
  scrollTrigger: {
    trigger: ".parallax-img",
    start: "top center", // the default values
    end: "bottom top",
    scrub: true
  }, 
});

/*----- Gallery parallaxs ---*/
gsap.to(".parallax-img-bg-first", {
  // yPercent: -40,
  yPercent: -10,
  ease: "easeIn",
  scrollTrigger: {
    trigger: ".hd-container-pseudo-right",
    // start: "top +=100px", // the default values
    // end: "bottom top",
    scrub: true
  }, 
});

// ScrollTrigger.matchMedia({
	
  // desktop
  // "(min-width: 768px)": function() {
    // setup animations and ScrollTriggers for screens 800px wide or greater (desktop) here...
    // These ScrollTriggers will be reverted/killed when the media query doesn't match anymore.       
  //   gsap.to(".firstGalleryText", {
  //     yPercent: 110,
  //     ease: "easeIn",
  //     scrollTrigger: {
  //       trigger: ".hd-container-pseudo-right",
  //       start: "top top", // the default values
  //       // // end: "bottom bottom",
  //       scrub: true
  //     }, 
  //   });
  // },

  // all 
  // "all": function() {
    // ScrollTriggers created here aren't associated with a particular media query,
    // so they persist.
  // }
	
// }); 

gsap.to(".parallax-img-bg-second", {
  // yPercent: -28,
  yPercent: -10,
  ease: "none",
  scrollTrigger: {
    trigger: ".firstGalleryImg",
    start: "top +=200px",
    end: "bottom top",
    scrub: true
  }, 
});

gsap.to(".parallax-img-bg-third", {
  yPercent: -10,
  ease: "none",
  scrollTrigger: {
    trigger: ".secondGalleryImg",
    start: "top bottom",
    end: "bottom top",
    scrub: true
  }, 
});

  gsap.to(".parallax-img-bg-fourth", {
    // yPercent: -22,
    yPercent: -10,
    ease: "none",
    scrollTrigger: {
      trigger: ".thirdGalleryImg",
      start: "top center",
      end: "bottom top",
      scrub: true
    }, 
  });
// ---- End Gallery paralaxs ----//

// gsap.utils.toArray(".galleryImgContainer .parallax-img-bg").forEach((section, i) => {
//   const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

//   gsap.fromTo(section,{ 
//     yPercent: 20
//   }, {
//     scrollTrigger: {
//       trigger: section.parentElement,
//       scrub: true
//     },
//     yPercent: -20,
//     ease: "none"
//   });
// });


// });


// gsap.utils.toArray(".galleryImgContainer .parallax-img-bg").forEach((section, i) => {
//   const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

//   gsap.fromTo(section,{ 
//     y: -heightDiff
//   }, {
//     scrollTrigger: {
//       trigger: section.parentElement,
//       scrub: true
//     },
//     y: 0,
//     ease: "none"
//   });
// });

// gsap.to(".secondGalleryImg", {
//   yPercent: -15,
//   ease: "easeIn",
//   scrollTrigger: {
//     trigger: ".firstGalleryImg",
//     start: "center +=100px", // the default values
//     // // end: "bottom bottom",
//     scrub: true
//   }, 
// });

// gsap.to(".gallery-effect", {
//   yPercent: 20,
//   ease: "none",
//   scrollTrigger: {
//     trigger: ".hd-container-pseudo-right",
//     start: "bottom +=100px", // the default values
//     // end: "bottom bottom",
//     scrub: true
//   }, 
// });

// function pormenoresParallax() {
//   windowWidth = $(window).width();
//   if (windowWidth < )
// }


// gsap.to(".gallery-parallax", {
//   yPercent: 10,
//   ease: "none",
//   scrollTrigger: {
//     trigger: ".hd-container-pseudo-right",
//     start: "top top", // the default values
//     end: "bottom top",
//     scrub: true
//   }, 
// });
// const appleSequenceImages = [];
// const treeSequenceImages = [];

// for (let i = 0; i <= 70; i ++) {
//   appleSequenceImages.push(`${`${i}`.slice(-4)}.png`);
// }
// for (let i = 0; i <= 98; i ++) {
//   treeSequenceImages.push(`${`000${i}`.slice(-3)}.jpg`);
// }

// const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

// class EventEmitter {
//   listeners = {}
//   addListener(eventName, fn) {
//     this.listeners[eventName] = this.listeners[eventName] || [];
//     this.listeners[eventName].push(fn);
//     return this;
//   }
//   on(eventName, fn) {
//     return this.addListener(eventName, fn);
//   }
//   once(eventName, fn) {
//     this.listeners[eventName] = this.listeners[eventName] || [];
//     const onceWrapper = () => {
//       fn();
//       this.off(eventName, onceWrapper);
//     }
//     this.listeners[eventName].push(onceWrapper);
//     return this;
//   }
//   off(eventName, fn) {
//     return this.removeListener(eventName, fn);
//   }
//   removeListener (eventName, fn) {
//     let lis = this.listeners[eventName];
//     if (!lis) return this;
//     for(let i = lis.length; i > 0; i--) {
//       if (lis[i] === fn) {
//         lis.splice(i,1);
//         break;
//       }
//     }
//     return this;
//   }
//   emit(eventName, ...args) {
//     let fns = this.listeners[eventName];
//     if (!fns) return false;
//     fns.forEach((f) => {
//       f(...args);
//     });
//     return true;
//   }
//   listenerCount(eventName) {
//     let fns = this.listeners[eventName] || [];
//     return fns.length;
//   }
//   rawListeners(eventName) {
//     return this.listeners[eventName];
//   }
// }
// class Canvas {
//   constructor(e) {
//     this.images = e.images;
//     this.container = e.container;
//     this.cover = e.cover;
//     this.displayIndex = 0;
//   }
  
//   setup() {
//     this.canvas = document.createElement("canvas");
//     this.container.appendChild(this.canvas);
//     this.ctx = this.canvas.getContext('2d')
    
//     window.addEventListener('resize', () => this.resize());
//     this.resize();
//   }
  
//   renderIndex(e) {
//     if (this.images[e]) {
//         return this.drawImage(e);
//     }
//     // Find closest loaded image
//     for (var t = Number.MAX_SAFE_INTEGER, r = e; r >= 0; r--)
//         if (this.images[r]) {
//             t = r;
//             break
//         }
//     for (var n = Number.MAX_SAFE_INTEGER, i = e, o = this.images.length; i < o; i++)
//         if (this.images[i]) {
//             n = i;
//             break
//         }
//     this.images[t] ? this.drawImage(t) : this.images[n] && this.drawImage(n)
//   }
  
//   drawImage(e) {
//     this.displayIndex = e,
//     this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
//     const x = Math.floor((this.canvas.width - this.images[this.displayIndex].naturalWidth) / 2);
//     const y = Math.floor((this.canvas.height - this.images[this.displayIndex].naturalHeight) / 2);
//     if (this.cover) {
      
//       this.drawImageCover(this.ctx, this.images[this.displayIndex]);
//     } else {
//       this.ctx.drawImage(this.images[this.displayIndex], x, y);
//     }
//   }
  
//   resize() {
//     const w = this.container.clientWidth;
//     const h = this.container.clientHeight;
//     this.canvas.style.height = `${h}px`;
//     this.canvas.style.width = `${w}px`;
//     this.canvas.height = h;
//     this.canvas.width = w;
    
//     this.renderIndex(this.displayIndex);
//   }
  
//   /**
//  * By Ken Fyrstenberg Nilsen
//  *
//  * drawImageProp(context, image [, x, y, width, height [,offsetX, offsetY]])
//  *
//  * If image and context are only arguments rectangle will equal canvas
// */
//   drawImageCover(ctx, img, x, y, w, h, offsetX, offsetY) {

//       if (arguments.length === 2) {
//           x = y = 0;
//           w = ctx.canvas.width;
//           h = ctx.canvas.height;
//       }

//       // default offset is center
//       offsetX = typeof offsetX === "number" ? offsetX : 0.5;
//       offsetY = typeof offsetY === "number" ? offsetY : 0.5;

//       // keep bounds [0.0, 1.0]
//       if (offsetX < 0) offsetX = 0;
//       if (offsetY < 0) offsetY = 0;
//       if (offsetX > 1) offsetX = 1;
//       if (offsetY > 1) offsetY = 1;

//       var iw = img.width,
//           ih = img.height,
//           r = Math.min(w / iw, h / ih),
//           nw = iw * r,   // new prop. width
//           nh = ih * r,   // new prop. height
//           cx, cy, cw, ch, ar = 1;

//       // decide which gap to fill    
//       if (nw < w) ar = w / nw;                             
//       if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
//       nw *= ar;
//       nh *= ar;

//       // calc source rectangle
//       cw = iw / (nw / w);
//       ch = ih / (nh / h);

//       cx = (iw - cw) * offsetX;
//       cy = (ih - ch) * offsetY;

//       // make sure source rectangle is valid
//       if (cx < 0) cx = 0;
//       if (cy < 0) cy = 0;
//       if (cw > iw) cw = iw;
//       if (ch > ih) ch = ih;

//       // fill image in dest. rectangle
//       ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
//   }
// }
// class ImgLoader extends EventEmitter {
//   constructor(opts) {
//     super();
//     this.images = opts.imgsRef;
//     this.imageNames = opts.images;
//     this.imagesRoot = opts.imagesRoot;
//     this.sequenceLength = opts.images.length;
//     this.priorityFranes = opts.priorityFrames;
//     this.complete = false;
//     this.loadIndex = 0;
    
//     this.priorityQueue = this.createPriorityQueue();
//     this.loadingQueue = this.createLoadingQueue();
    
//     this.loadNextImage();
//   }
  
//   loadImage(e) {
//     if (this.images[e]) {
//       return this.loadNextImage();
//     }
//     const onLoad = () => {
//       img.removeEventListener('load', onLoad);
//       this.images[e] = img;
      
//       if (e === 0) {
//         this.emit('FIRST_IMAGE_LOADED');
//       }
//       this.loadNextImage();
//     }
//     const img = new Image;
//     img.addEventListener('load', onLoad);
//     img.src = (this.imagesRoot ? this.imagesRoot : '') + this.imageNames[e];
//   }
  
//   loadNextImage() {
//     if (this.priorityQueue.length) {
//       this.loadImage(this.priorityQueue.shift());
//       if (!this.priorityQueue.length) {
//         this.emit('PRIORITY_IMAGES_LOADED');
//       }
//     } else if (this.loadingQueue.length) {
//         this.loadImage(this.loadingQueue.shift())
//     } else {
//       this.complete = true;
//       this.emit('IMAGES_LOADED');
//     }
//   }
  
//   createPriorityQueue() {
//     const p = this.priorityFrames || [];
//     if (!p.length) {
//       p.push(0);
//       p.push(Math.round(this.sequenceLength / 2));
//       p.push(this.sequenceLength - 1);
//     }
//     return p;
//   }
  
//   createLoadingQueue() {
//     return this.imageNames.map((s, i) => i).sort((e, n) => {
//        return Math.abs(e - this.sequenceLength / 2) - Math.abs(n - this.sequenceLength / 2)
//     });
//   }
// }
// class ScrollSequence {
//   constructor(opts) {
//     this.opts = {
//       container: 'body',
//       starts: 'out',
//       ends: 'out',
//       imagesRoot: '',
//       cover: false,
//       ...opts
//     }
//     this.container = typeof opts.container === 'object' ? 
//       opts.container : 
//       document.querySelector(opts.container);
    
//     this.scrollWith = !opts.scrollWith ? 
//       this.container : 
//       typeof opts.scrollWith === 'object' ? 
//         opts.scrollWith : 
//         document.querySelector(opts.scrollWith);
    
//     this.images = Array(opts.images.length);
//     this.imagesToLoad = opts.images;
//     this.priorityFrames = opts.priorityFrames;
    
//     this.loader = new ImgLoader({
//       imgsRef: this.images,
//       images: this.imagesToLoad,
//       imagesRoot: this.opts.imagesRoot,
//       priorityFrames: this.priorityFrames
//     });
    
//     this.canvas = new Canvas({
//       container: this.container,
//       images: this.images,
//       cover: this.opts.cover
//     });
    
//     this.init();
//   }
  
//   init() {
//     this.canvas.setup();
//     this.loader.once('FIRST_IMAGE_LOADED', () => {
//       this.canvas.renderIndex(0);
//     })
//     this.loader.once('PRIORITY_IMAGES_LOADED', () => {
//       window.addEventListener('scroll', () => this.changeOnWindowScroll());
//     })
//     this.loader.once('IMAGES_LOADED', () => {
//       console.log('Sequence Loaded');
//     })
//   }
  
//   changeOnWindowScroll() {
//     const step = 100 / (this.images.length - 1);
//     const mapToIndex = Math.floor(this.percentScrolled / step);
//     requestAnimationFrame(() => this.canvas.renderIndex(mapToIndex));
//   }
  
//   get percentScrolled() {
//     const {starts, ends} = this.opts;
//     const el = this.scrollWith;
//     const doc = document.documentElement;
//     const clientOffsety = doc.scrollTop || window.pageYOffset;
//     const elementHeight = el.clientHeight || el.offsetHeight;
//     const clientHeight = doc.clientHeight;
//     let target = el;
//     let offsetY = 0;
//     do {
//         offsetY += target.offsetTop;
//         target = target.offsetParent;
//     } while (target && target !== window);
    
//     let u = (clientOffsety - offsetY);
//     let d = (elementHeight + clientHeight)
    
//     if (starts === 'out') u += clientHeight;
//     if (ends === 'in') d -= clientHeight;
//     if (starts == 'in') d -= clientHeight;
//     // start: out, ends: out
//     // const value = ((clientOffsety + clientHeight) - offsetY) / (clientHeight + elementHeight) * 100;
    
//     //start: in, ends: out
//     // const value = (clientOffsety - offsetY) / (elementHeight) * 100;
    
//     //start: out, ends: in
//     // const value = ((clientOffsety + clientHeight) - offsetY) / (elementHeight) * 100;
    
//     // Start: in, ends: in
//     // (clientOffsety - offsetY) / (elementHeight - clientHeight)
    
//     const value = u / d * 100;
//     return value > 100 ? 100 : value < 0 ? 0 : value;
//   }
// }

// const appleSequence = new ScrollSequence({
//   container: '.apple-sequence',
//   scrollWith: '.apple-container',
//   images: appleSequenceImages,
//   imagesRoot: 'assets/media/Phonebooth/frames/teste/',
//   priorityFrames: [0, 20, 40, 60, 90],
//   cover: true,
//   playUntil: 'scroll-out',
//   starts: 'in'
// });

// // const treeSequence = new ScrollSequence({
// //   container: '.tree-sequence',
// //   scrollWith: '.tree-container',
// //   images: treeSequenceImages,
// //   imagesRoot: 'https://jacobbelanger.com/assets/sequence/',
// //   priorityFrames: [0, 20, 40, 60, 90],
// //   cover: true,
// //   starts: 'out',
// //   ends: 'in'
// // });

// // END SCROLL_SEQUENCE CODE

// // ScrollOut({
// //   targets: '.speak',
// //   cssProps: {
// //     viewportY: true,
// //     visibleY: true
// //   }
// // });
};

export { doorSequence };